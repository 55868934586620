body {
  margin: 0;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-table-wrapper {
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  padding: 8px;
}

.scroll-pretty::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.scroll-pretty::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(212, 212, 212);
  border-radius: 10px;
}

/* Handle */
.scroll-pretty::-webkit-scrollbar-thumb {
  background: rgb(172, 181, 189);
  border-radius: 10px;
}

/* Handle on hover */
.scroll-pretty::-webkit-scrollbar-thumb:hover {
  background: #0089b3;
}
