.slider_rank {
  margin-bottom: 5px !important;
}
.chat-panel-list {
  padding: 6px 0px !important;
  border-radius: 20px 0px 0px 20px;
}

.chat-panel-list-badge {
  width: 100%;
}
.chat-panel-list .ant-list-item-meta-description {
  width: 190px;
  font-size: 12px !important;
  /* display: flex;
          align-items: flex-start;*/
  margin-top: -5px;
  width: 98%;
}

.chat-panel-list .ant-list-item-meta-content > h4 {
  max-height: 20px;
}

.chat-panel-list .ant-list-item-meta-description .PanelMessageAuthor {
  max-height: 20px;
  color: #0a0a0a;
  justify-content: start;
  z-index: 4;
  font-size: 11px;
  text-align: start;
  align-content: start;
  width: 60%;
}
.chat-panel-list .ant-list-item-meta-description .PanelMessageDate {
  width: 39%;
}

.chat-panel-list .ant-list-item-meta-description .PanelMessageSecondary {
  padding-right: 0px;
  padding-left: 5px;
  font-size: 11px;
  align-items: center;
  display: flex;
  flex-direction: row;
  max-height: 20px;
  color: #bbbbbb;
  justify-content: flex-end;
  z-index: 4;
  overflow: hidden;
  text-align: right;
  font-size: 11px;
  border-radius: 10px;
  margin-top: -6px;
  backdrop-filter: blur(10px);
}

.chat-panel-list .ant-list-item-meta-description .PanelMessageDate > span {
  margin-left: 5px;
}

.chat-panel-list .ant-list-item-meta-description .PanelMessageLastMessage {
  float: left;
  color: #888888;
  width: 100%;
  inline-size: 100%;
  overflow: hidden;
  height: 40px;
  max-height: 40px;
  z-index: 1;
}

.chat-panel-list .ant-list-item-meta-description .PanelMessageSecondaryInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.chat-panel-list .ant-list-item-meta-description .PanelMessageSessionName {
  color: #000000;
  display: inline-flex;
  width: 100px;
  inline-size: 100px;
  font-size: 11px;
  overflow: hidden;
  height: 20px;
  max-height: 20px;
  z-index: 3;
  text-align: right;
  justify-content: flex-end;
  padding-right: 10px;
}

.ant-list-item-meta-title {
  width: 180px;
  inline-size: 95%;
  overflow: hidden;
  height: 25px;
}

.chat-panel-Panellist {
  width: 100%;
}

.chat-window {
  padding: 0px;
  background-color: #efeae2;
  max-height: 80vh;
  border-radius: 10px;
  margin: 5px;
}
.chat-messages,
.WebHook-messages {
  height: 80vh;
  overflow-y: scroll;
  padding: 8px;
  position: relative;
  background: #efe7dd url("images/background.jpg") repeat;
  z-index: 0;
  padding: 5px;
  border-radius: 10px;
}

.chat-session-variables {
  height: 75vh;
  overflow-y: scroll;
  overflow-x: hidden !important;
  padding: 8px;
  z-index: 0;
  border-radius: 10px;
  margin: 5px;
}
.chat-session-variable {
  padding: 6px;
}
.WebHook-messages {
  background: #ffffff;
}
.input-area {
  padding-top: 8px;
}

.timestamp {
  display: inline;
  width: 100%;
  top: 34px;
  font-size: 0.7rem;
  min-height: 15px;
  padding-left: 0px;
  float: left;
  left: 0px;
  color: #a3a3a3;
}
.options {
  display: inline;
  width: 100%;
  position: absolute;
  top: 8px;
  font-size: 0.7rem;
  min-height: 15px;
  float: left;
  left: 0px;
  color: #914716;
}
.options p {
  display: inline;
  padding-left: 20px;
}

.bubble {
  min-height: 30px;
  margin-bottom: 25px;
}
.bubble_sent {
  display: inline-flex;
  justify-content: flex-end;
}

.message_sent > .timestamp {
  display: inline-flex;
  justify-content: flex-end;
  padding-right: 5px;
  text-align: right;
}
.bubble_received {
  display: inline-flex;
  justify-content: flex-start;
}

.bubble > span {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #1e74cf;
}

.message {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #f5f5f5;
  margin: 2px;

  width: auto;
  box-shadow: 3px 3px 10px #cccccb;
  background: linear-gradient(0deg, #fffdfd 5%, #ebebea 100%);
}
.message_sent {
  background: linear-gradient(0deg, #e5f7fa 5%, #b9eafd 100%);
  padding: 10px;
  border: 1px solid #e09e9e;
  margin: 2px;
  text-align: end;
}

.message_system,
.message_bot {
  margin-top: -14px;
  border-radius: 40px;
  background: transparent;
  padding: 5px;
  margin-right: 15px;
  border: transparent;
  text-align: right;
  font-size: 0.7rem;
  min-width: 100px;
  color: #2e2016 !important ;
  box-shadow: none;
}
.message_message,
.message_inputText {
  border-radius: 5px;
  background: linear-gradient(0deg, #e5fae7 5%, #b9fdd0 100%);
  padding: 7px;
  border: 1px solid #c6d1c3;
  margin: 2px;
  margin-right: 15px;
  text-align: end;
  font-size: 0.8rem;
  min-width: 50px;
  max-width: 400px;
}
.message_optionButton,
.message_optionList {
  border-radius: 5px;
  background: linear-gradient(0deg, #e5fae7 5%, #b9fdd0 100%);
  border: 1px solid #c6d1c3;
  margin: 2px;
  margin-top: -12px;
  margin-right: 15px;
  text-align: end;
  font-size: 0.8rem;
  min-width: 150px;
  max-width: 400px;
}

.message_agent,
.message_myself {
  border-radius: 5px;
  background: linear-gradient(0deg, #c2f1c7 50%, #b9fdd0 100%);
  padding: 7px;
  border: 1px solid #bfc5bd;
  margin: 2px;
  margin-right: 15px;
  text-align: end;
  font-size: 0.8rem;
  color: #000000;
}

.message_customer {
  border-radius: 5px;
  background: linear-gradient(0deg, #e5f7fa 5%, #b9eafd 100%);
  padding: 7px;
  border: 1px solid #b2cbf1;
  margin: 2px;
  margin-left: 15px;
  text-align: start;
  font-size: 0.8rem;
  color: #000000;
  min-width: 50px;
  width: 300px !important;
}
