.react-flow__edge-path {
  stroke: #99a1e5;
  stroke-width: 2;
}

.dndnode {
  min-height: 60px;
  max-width: 100px;
  padding: 2px !important;
}

.dndnode span {
  font-size: 12px;
  top: -5px;
  position: relative;
}
.dndnode .anticon {
  font-size: 30px;
}

.HandleSource {
  border: 1px solid #353535;
  background: #555;
  width: 15px;
  height: 15px;
}
.HandleSource_name {
  left: 10px;
  display: inline-flex;
  margin-left: 10px;
  min-width: 250px;
  padding-top: 8px;
  color: #aaaaaa;
}
.HandleTarget {
  border: 1px solid #353535;
  background: rgb(85, 189, 81);
  width: 15px;
  height: 15px;
}

.react-flow__node {
  font-size: 12px;
  background: #ffffff;
  border: 1px solid #5a5a5a;
  border-radius: 4px;
  text-align: center;
  min-width: 40px;
  min-height: 50px;
  box-shadow: 2px 2px 2px #868686;
  padding: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.block-node {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80px;
  min-height: 50px;
  z-index: 10000;
}

.node-type {
  font-size: 8px;
  width: 100px;
  text-align: right;
  display: inline-flex;
  align-self: flex-end;
  justify-content: flex-end;
  min-height: 14px;
  font-weight: 300;
  text-align: flex-end;
  padding-right: -20px;
  margin-bottom: -16px;
  position: absolute;
  right: -10px;
  top: -15px;
  border: 0px solid #444455;
}

.node-actions {
  font-size: 8px;
  width: 60px;
  text-align: right;
  display: inline-flex;
  align-self: flex-end;
  justify-content: flex-end;
  min-height: 14px;
  font-weight: 300;
  text-align: flex-end;
  padding-right: -20px;
  margin-bottom: -16px;
  position: absolute;
  left: -50px;
  top: 10px;
  border: 0px solid #ffff66;
  z-index: 10;
}

.node-name {
  font-size: 8px;
  width: 100%;
  text-align: left;
  padding-left: 5px;
  display: block;
  min-height: 14px;
  position: absolute;
  font-weight: 800;
  left: -10px;
  top: -15px;
  border: 0px solid #007788;
}
.node-message {
  padding: 5px;
  min-width: 40px;
  max-height: 80px;
  overflow-y: hidden;
  height: auto;
  font-size: 8px;
  min-width: 80px;
  text-align: left;
  word-wrap: break-word;
  border: 0px solid #ff5500;
}

.btn-secondary {
  background-color: #dadada;
  color: #000000;
  border: 1px solid #000000;
}

.react-flow__node-start {
  border-color: #149157;
  background-color: #00aa00;
  width: 50px;
}

.react-flow__node-end {
  border-color: #34779e;
  background-color: #55a5c4;
  width: 50px;
}

.react-flow__node-conditional {
  border-color: rgb(241, 127, 60);
  background-color: rgb(255, 236, 219);
  width: 70px;
}

.react-flow__node-HttpRequest {
  background-color: rgb(244, 255, 219);
}

.react-flow__node-optionButton {
  background-color: rgb(227, 219, 255);
}

.react-flow__node-optionButton .node-message {
  max-height: 40px;
}

.react-flow__node-optionList {
  background-color: rgb(244, 219, 255);
  max-height: 340px;
}

.react-flow__node-setVariable {
  background-color: rgb(253, 212, 185);
  width: 50px;
  border-color: #149157;
}

.react-flow__node-setAtribute {
  background-color: rgb(253, 252, 185);
  width: 50px;
  border-color: #149157;
}

.react-flow__node-switch {
  background-color: antiquewhite;
  min-width: 50px;
  width: 70px;
  min-height: 80px;
}

.react-flow__node-video {
  background-color: rgb(255, 0, 102);
  border-radius: 50%;
  width: 50px;
}

.react-flow__node-audio {
  background-color: rgb(255, 0, 157);
  border-radius: 50%;
  width: 50px;
}
